
const Node = ({
	item,
	hasChildren,
	level,
	onToggle,
	selected,
	selectCikkItem,
	setSelectCikkItem,
}) => {
	
	let calcPadding = 0
	let depthClass = ""

	if (level === 0) {
		calcPadding = 10
	} else {
		calcPadding = level * 20
	}

	switch (level) {
		case 0:
			depthClass = "root-depth"
			break
		case 1:
			depthClass = "first-depth"
			break
		case 2:
			depthClass = "second-depth"
			break
		case 3:
			depthClass = "third-depth"
			break
		case 4:
			depthClass = "fourth-depth"
			break

		default:
			break
	}

	function handleClick() {
		setSelectCikkItem({ kod: item.kod, id: item.id, nev: item.nev })
		onToggle()
	}


	return (
		<div
			className={`tree-leafs ${depthClass}`}
			style={{ paddingLeft: `${calcPadding}px` }}
			onClick={handleClick}
		>
			{hasChildren && (
				<i className={`fa-solid fa-caret-${selected ? "down" : "right"}`}></i>
			)}
			<span>
				{item.nev.charAt(0).toUpperCase() + item.nev.substring(1).toLowerCase()}
			</span>
			{item.id === selectCikkItem.id && (
				<div className="selected-wrap">
					<div className="selected-circle">
						<i id="selected-check" className="fa-solid fa-circle-check"></i>
					</div>
				</div>
			)}
		</div>
	)

}
export default Node