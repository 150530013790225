import { Link } from "react-router-dom"
import "./NotFound.css"

export default function ServerError(props) {

    return (
			<div className="notfound-card">
				<h1>
					5<span>0</span>0
				</h1>
				<h2>Szerver hiba!</h2>

				<Link to="/">
					<button className="btn">Kezdő oldal</button>
				</Link>
			</div>
		)
}; 
    
   
