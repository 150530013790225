import React, { useState } from "react"
import Node from "./Node"

const Branch = ({ item, level, selectCikkItem, setSelectCikkItem }) => {
	const [selected, setSelected] = useState(item.selected ?? false)

	const hasChildren = item.children && item.children.length !== 0

	const renderBranches = () => {
		if (hasChildren) {
			const newLevel = level + 1

			return item.children.map((child) => {
				return (
					<Branch
						key={child.id}
						item={child}
						level={newLevel}
						selectCikkItem={selectCikkItem}
						setSelectCikkItem={setSelectCikkItem}
					/>
				)
			})
		}

		return null
	}

	const toggleSelected = (e) => {
		setSelected((prev) => !prev)
	}

	return (
		<>
			<Node
				item={item}
				selected={selected}
				hasChildren={hasChildren}
				level={level}
				onToggle={toggleSelected}
				selectCikkItem={selectCikkItem}
				setSelectCikkItem={setSelectCikkItem}
			/>

			{selected && renderBranches()}
		</>
	)
}

export default Branch
