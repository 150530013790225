import React, { useState, useEffect } from "react"
import useAxios from "../api/useAxios"
import { Slide, ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Filter from "../components/Filter"
import TableLogisztika from "../components/Tables/TableLogisztika"
import { getFirstLastDay } from "../utils/DateUtil"
import ScrollButton from "../components/ScrollButton"

export default function Logisztika(props) {
	const today = new Date()
	const currentMonth = today.getMonth()
	const currentYear = today.getFullYear()

	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [listOfDays, setListOfDays] = useState([])


	const api = useAxios()

	function getDays(year, month) {
		const daysOfMonth = []
		let days = getFirstLastDay(year, month)
		let countDay = days[0]
		const lastDay = days[1]

		for (
			countDay;
			countDay <= lastDay;
			countDay.setDate(countDay.getDate() + 1)
		) {
			let dateString = new Date(
				countDay.getTime() - countDay.getTimezoneOffset() * 60000
			)
				.toISOString()
				.split("T")[0]
			daysOfMonth.push(dateString)
		}
		return daysOfMonth
	}

	//run at first render
	useEffect(() => {
		const currDate = currentYear + "." + (currentMonth + 1)
		getData(currDate)
		// eslint-disable-next-line
	}, [])


	useEffect(() => {
		if (data.length > 0) {
			setLoading(false)
		} else {
			setLoading(true)
		}
	}, [data.length])


	const getData = async (selectedDate) => {
		setLoading(true)
		setData([])

		const selectedYear = parseInt(selectedDate.split(".")[0])
		let selectedMonth = selectedDate.split(".")[1]
		if (selectedMonth.charAt(0) === "0") {
			selectedMonth = parseInt(selectedMonth.charAt(1))
		} else {
			selectedMonth = parseInt(selectedMonth)
		}

		let days = getFirstLastDay(selectedYear, selectedMonth - 1)

		setListOfDays(getDays(selectedYear, selectedMonth - 1))

		const start = new Date(
			days[0].getTime() - days[0].getTimezoneOffset() * 60000
		)
			.toISOString()
			.split("T")[0]
		const end = new Date(
			days[1].getTime() - days[1].getTimezoneOffset() * 60000
		)
			.toISOString()
			.split("T")[0]

		const response = await api.get(`logisztika/${start}/${end}`, { parse: true })
		if (response.status === 200) {

			setData(response.data)
			//response 200 ok
			//response 404 no date
			//response 500 error
		}
	}

	return (
		<div className="main">
			<ScrollButton />
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				pauseOnHover
				theme="dark"
				transition={Slide}
			/>

			<div className="main-wrapper">
				<Filter
					selectCikkItem={false}
					toast={toast}
					monthPicker={true}
					selectedYear={currentYear}
					selectedMonth={currentMonth}
					getData={getData}
					loading={loading}
				/>
				{!loading && <TableLogisztika data={data} listOfDays={listOfDays} />}
			</div>
		</div>
	)
}; 
    
   
