import React, {useEffect, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { decodeToken } from "react-jwt"
import { Outlet } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext"
import useAxios from "../api/useAxios"

import Nav from "../pages/Nav"

function WithNav() {

	const { setCurrentUser } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const navigate = useNavigate()
	const api = useAxios()

	useEffect(() => {
		const refreshToken = localStorage.getItem("refreshtoken")

		const getAccessToken = async () => {
			try {
				if (refreshToken !== null) {
					const res = await api.post("/token", { token: refreshToken })
					const user = {
						email: decodeToken(refreshToken).email,
						accessToken: res.data.accessToken,
						isAdmin: decodeToken(refreshToken).isAdmin,
					}
					setCurrentUser(user)
				}
				setLoading(false)
				
			} catch (error) {
				navigate("/error")
			}
		}

		getAccessToken()
		
		// eslint-disable-next-line
	}, [])
	
	return (
		<>
			{!loading && (
				<>
					<Nav />
					<Outlet />
				</>
			)}
		</>
	)
}

export default WithNav