import { useState } from "react"
import "./Charts.css"
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts"


export default function StackedArea ({ filteredData, bolt }) {
	//format data before give it to chart
	
	//for chart filtering
	const [filterView, setFilterView] = useState("Ft")	


	function formatCurr(value) {
		return Intl.NumberFormat("hu").format(value)
	}

	//format yAxis label
	function decimalText(){
		if (filterView === "Ft"){
			return "Forint (ezer)."
		} else if (filterView === "Vevő"){
			return "Forint."
		} else if (filterView === "Százalék"){
			return "Százalék %"
		}
	}

	//select areas corresponding to filtered view
	function selectedArea(){
		if (filterView === "Ft"){
			return (
				<>
					<Area
						type="monotone"
						dataKey="nettofogyar"
						name="Nettó Fogyár"
						unit={"Ft"}
						stackId="1"
						fill="red"
						stroke="red"
					/>
					<Area
						type="monotone"
						dataKey="arres"
						name="Árrés tömeg"
						unit={"Ft"}
						fillOpacity="0.7"
						stackId="1"
						fill="#010324"
						stroke="#010324"
					/>
					<Area
						dataKey="vevoszam"
						name="Vevőszám"
						unit={"db"}
						fill="#0eb039"
						stroke="#0eb039"
					/>
					<Area
						dataKey="arresSzazalek"
						name="Árrés százalék"
						unit={"%"}
						fill="#d3d62d"
						stroke="#d3d62d"
					/>
				</>
			)
		} else if (filterView === "Vevő"){
			return (
				<Area
					dataKey="vevoszam"
					name="Vevőszám"
					unit={"db"}
					fill="#0eb039"
					stroke="#0eb039"
				/>
			)
		} else if (filterView === "Százalék"){
			return (
				<Area
					dataKey="arresSzazalek"
					name="Árrés százalék"
					unit={"%"}
					fill="#050c87"
					stroke="#050c87"
				/>
			)
		}
	}

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			const days = [
				"Vasárnap",
				"Hétfő",
				"Kedd",
				"Szerda",
				"Csütörtök",
				"Péntek",
				"Szombat",
			]

			const d = new Date(label)
			var dayName = days[d.getDay()]

			//const newValue = formatCurr(payload[0].value)
			if (filterView === "Ft") {
				let fogyar = formatCurr(
					Number(payload[0].value) + Number(payload[1].value)
				)
				return (
					<div className="custom-tooltip">
						<table>
							<tbody>
								<tr>
									<td>Dátum:</td>
									<td className="tooltip-data">{label}</td>
								</tr>
								<tr>
									<td>Nap:</td>
									<td className="tooltip-data">{dayName}</td>
								</tr>
								<tr>
									<td>Fogy. ár:</td>
									<td className="tooltip-data">{fogyar}</td>
								</tr>
								<tr>
									<td>Árrés tömeg:</td>
									<td className="tooltip-data">
										{formatCurr(payload[1].value)}
									</td>
								</tr>
								<tr>
									<td>Árrés százalék:</td>
									<td className="tooltip-data">
										{formatCurr(payload[3].value)}%
									</td>
								</tr>
								<tr>
									<td>Vevő szám:</td>
									<td className="tooltip-data">
										{formatCurr(payload[2].value)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				)
			} else if (filterView === "Vevő") {
				return (
					<div className="custom-tooltip">
						<table>
							<tbody>
								<tr>
									<td>Dátum:</td>
									<td className="tooltip-data">{label}</td>
								</tr>
								<tr>
									<td>Vevő szám:</td>
									<td className="tooltip-data">
										{formatCurr(payload[0].value)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				)
			} else if (filterView === "Százalék") {
				return (
					<div className="custom-tooltip">
						<table>
							<tbody>
								<tr>
									<td>Dátum:</td>
									<td className="tooltip-data">{label}</td>
								</tr>
								<tr>
									<td>Árrés százalék:</td>
									<td className="tooltip-data">
										{formatCurr(payload[0].value)}%
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				)
			}
		}
			

		return null
	}

	const renderLegend = (props) => {
		let { payload } = props


		//legends filter, need to change view to little numbers
		if(filterView === "Ft") {
			payload = payload.filter(entry => entry.dataKey !== "vevoszam" && entry.dataKey !== "arresSzazalek")
		}

		return (
			<>
				{payload.map((entry, index) => (
					<span key={`item-${index}`}>
						<div
							style={{
								display: "inline-block",
								width: "12px",
								height: "12px",
								backgroundColor: entry.color,
							}}
						></div>
						<span
							className="legend-text"
							style={{
								color: entry.color,
							}}
						>
							{entry.value}
						</span>
					</span>
				))}
			</>
		)
	}

	const filterViewChange = (e) => {
		setFilterView(e.target.value)
	}

	return (
		<div className="block">
			<div className="block-title">
				<form className="viewform">
					<input
						type="radio"
						name="nezet"
						value="Ft"
						id="Ft"
						checked={filterView === "Ft"}
						onChange={filterViewChange}
						label="Ft"
					/>

					<input
						type="radio"
						name="nezet"
						value="Vevő"
						id="Vevő"
						checked={filterView === "Vevő"}
						onChange={filterViewChange}
						label="Vevő"
					/>

					<input
						type="radio"
						name="nezet"
						value="Százalék"
						id="Százalék"
						checked={filterView === "Százalék"}
						onChange={filterViewChange}
						label="Százalék"
					/>
				</form>
				<h3 className="bolt-title-h">{bolt}</h3>
			</div>
			<ResponsiveContainer width="99%" aspect={2.5}>
				<AreaChart
					key={Math.random()}
					data={filteredData}
					margin={{
						top: 0,
						right: 40,
						left: 20,
						bottom: 40,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="datum"
						interval={filteredData.length < 16 ? 0 : 5}
						fontSize={12}
						angle={35}
						dy={10}
						dx={14}
						tickMargin={5}
						tickFormatter={(tick) => {
							let newTick = tick.substring(5)
							return newTick
						}}
					/>
					<YAxis
						tick={true}
						tickFormatter={(tick) => {
							if (tick === 0) {
								return tick
							}
							if (filterView === "Ft") {
								tick = tick / 1000
							}

							return tick.toLocaleString()
						}}
						dataKey={filteredData.fogyar}
						fontSize={12}
						type="number"
						domain={[0, "auto"]}
						label={{
							value: decimalText(),
							fontSize: 13,
							position: "left",
							angle: -90,
							dy: -40,
						}}
					/>
					<Legend
						align="left"
						wrapperStyle={{ position: "relative", left: "10%" }}
						content={renderLegend}
					/>
					<Tooltip
						content={<CustomTooltip />}
						wrapperStyle={{ outline: "none" }}
					/>
					{selectedArea()}
				</AreaChart>
			</ResponsiveContainer>
		</div>
	)
}
