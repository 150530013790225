import React from 'react'
import "./PwdInfo.css"

export default function PwdInfo({pwdLength, pwdDigit, pwdUc, pwdLc, pwdSpecial, minPwdLength}) {
    
    function getMark(pwd) {
        if (pwd) {
            return <i className="fa-regular fa-circle-check"></i>
        } else {
            return <i className="fa-sharp fa-regular fa-circle-xmark"></i>
        }
        
    }

    return (
			<div className="pwd-container">
				<h5 className='pwd-title'>Jelszó:</h5>
				<ul>
					<li>
						{getMark(pwdLength)} hosszúság {minPwdLength} karakter
					</li>
					<li>{getMark(pwdDigit)} Szám</li>
					<li>{getMark(pwdLc)} Kisbetű</li>
					<li>{getMark(pwdUc)} Nagybetű</li>
					<li>{getMark(pwdSpecial)} speciális karakter</li>
				</ul>
			</div>
		)
}