import React, { useState, useEffect } from "react"
import StackedArea from "../components/Charts/StackedArea"
import useAxios from "../api/useAxios"
import PieFogy from "../components/Charts/PieFogy"
import TableForg from '../components/Tables/TableForg'
import TableForgAll from '../components/Tables/TableForgAll'
import trafikBoltok from "../data/TrafikData"
import Filter from "../components/Filter"


import { Slide, ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {DateUtil} from "../utils/DateUtil"
import ScrollButton from "../components/ScrollButton"

export default function Trafik(props) {
    
	const months = 1
	const days = 0

	const [data, setData] = useState([])
	const [isChart, setIsChart] = useState(true)
	const [loading, setLoading] = useState(true)
	const [sumBoltok, setSumBoltok] = useState([])
	const [startDate, setStartDate] = useState(DateUtil(months, days).start)
	const [endDate, setEndDate] = useState(DateUtil(months, days).end)
	
	const api = useAxios()
	
	//run at first render
	useEffect(() => {
		getData()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		setSumBoltok(
			trafikBoltok.map((bolt) => {
				const filteredBolt = data.filter(fbolt => fbolt.egyseg === bolt.id)

				let fogyInitialValue = 0

				const fogySum = filteredBolt.reduce(function (accumulator, currentValue) {
						return accumulator + currentValue.fogyar
				}, fogyInitialValue)

				let vevoInitialValue = 0

				const vevoSum = filteredBolt.reduce(function (accumulator, currentValue) {
					return accumulator + currentValue.vevoszam
				}, vevoInitialValue)

				let arresInitialValue = 0

				const arresSum = filteredBolt.reduce(function (
					accumulator,
					currentValue
				) {
					return accumulator + currentValue.arres
				},
				arresInitialValue)

				let arresSzazalekInitialValue = 0
				
				const arresAvg = filteredBolt.reduce(function (
					accumulator,
					currentValue,
					index,
					array
				) {
						return (
							accumulator +
							currentValue.arresSzazalek / array.length
						)
				},
				arresSzazalekInitialValue)

				return { ...bolt, fogyar: fogySum, arres: arresSum, vevo: vevoSum, arresszazalek: arresAvg }
			})
		)
	}, [data])

	function filterData (bolt) {
		return data.filter((n) => n.egyseg === bolt)
	}
	
	const getData = async() =>{
		const response = await api.get(`blokktrafik/${DateUtil(months, days).fullDate}`,
			{ parse: true }
		)
		
		if(response.status === 200){
			setData(response.data)
			setLoading(false)
		}
	}

	function onOptionChange(e){
		const chart = e.target.value === 'true' ? true:false
		setIsChart(chart)
	}

	const getBlokkData = async (start, end) => {
		const fullDate = start + "/" + end
		setStartDate(start)
		setEndDate(end)
		const response = await api.get(`blokktrafik/${fullDate}`, { parse: true })

		if (response.status === 200) {
			setData(response.data)
		}
	}

    return (
			<div className="main">
				<ScrollButton />
				<ToastContainer
					position="top-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnHover
					theme="dark"
					transition={Slide}
				/>
				{!loading && (
					<div className="main-wrapper">
						<Filter
							selectCikkItem={false}
							toast={toast}
							getData={getBlokkData}
							onOptionChange={onOptionChange}
							months={months}
							days={days}
						/>

						{isChart ? (
							<>
								<PieFogy
									sumBoltok={sumBoltok}
									startDate={startDate}
									endDate={endDate}
								/>
								{trafikBoltok.map((bolt, index) => (
									<StackedArea
										key={index}
										bolt={bolt.name}
										filteredData={filterData(bolt.id)}
									/>
								))}
							</>
						) : (
							<>
								<TableForgAll
									sumBoltok={sumBoltok}
									startDate={startDate}
									endDate={endDate}
								/>
								{trafikBoltok.map((bolt, index) => (
									<TableForg
										key={index}
										bolt={bolt.name}
										filteredData={filterData(bolt.id)}
									/>
								))}
							</>
						)}
					</div>
				)}
			</div>
		)
}; 
    
   
