import React from 'react'
import boltok from "../../data/Data"
import "./table.css"

export default function TableGrill({ filteredData, bolt, listOfDays }) {

	let boltName = ''

	boltok.forEach(el => {
		if(el.id === bolt) {
			boltName=el.name
		} 	
	})

	filteredData.forEach(
		
		(row) => (
			(row.atadas_netto = row.atadas_netto
				? parseInt(row.atadas_netto)
				: 0),
			(row.mennyiseg = row.mennyiseg
				? parseInt(row.mennyiseg)
				: 0),
			(row.mennyiseg_np = row.mennyiseg_np
				? parseInt(row.mennyiseg_np)
				: 0),
			(row.ert_netto = row.ert_netto 
				? parseInt(row.ert_netto)
				: 0),
			(row.ert_netto_np = row.ert_netto_np
				? parseInt(row.ert_netto_np)
				: 0),
			(row.ert_brutto = row.ert_brutto 
				? parseInt(row.ert_brutto)
				: 0),
			(row.ert_brutto_np = row.ert_brutto_np
				? parseInt(row.ert_brutto_np)
				: 0)
		)
	)

	let atadasInit = 0
	let mennyisegInit = 0
	let mennyisegNpInit = 0
	let ertNettoInit = 0
	let ertNettoNpInit = 0
	let ertBruttoInit = 0
	let ertBruttoNpInit = 0

	const atadasSum = filteredData.reduce(function (acc, curr) {
		return acc + curr.atadas_netto	
	}, atadasInit)

	const mennyisegSum = filteredData.reduce(function (acc, curr) {
		return acc + curr.mennyiseg
	}, mennyisegInit)

	const mennyisegNpSum = filteredData.reduce(function (acc, curr) {
		return acc + curr.mennyiseg_np
	}, mennyisegNpInit)

	const ertNettoSum = filteredData.reduce(function (acc, curr) {
		return acc + curr.ert_netto
	}, ertNettoInit)

	const ertNettoNpSum = filteredData.reduce(function (acc, curr) {
		return acc + curr.ert_netto_np
	}, ertNettoNpInit)

	const ertBruttoSum = filteredData.reduce(function (acc, curr) {
		return acc + curr.ert_brutto
	}, ertBruttoInit)

	const ertBruttoNpSum = filteredData.reduce(function (acc, curr) {
		return acc + curr.ert_brutto_np
	}, ertBruttoNpInit)


	return (
		<div className="block-table">
			<div className="block-table-title">
				<table className="styled-table grill-table">
					<thead>
						<tr className="table-title-row">
							<th colSpan="8" className="table-bolt-row">
								<span className="title-color-table">{boltName}</span> Grill
								forgalom{" "}
								<span className="title-color-table">
									{listOfDays[0]} - {listOfDays[listOfDays.length - 1]}
								</span>{" "}
								között
							</th>
						</tr>
						<tr key="head-title" className="table-head-row grill-head-row">
							<th>{(atadasSum / mennyisegSum).toFixed(2)} Ft/db</th>
							<th></th>
							<th colSpan="2">Mennyiség</th>
							<th colSpan="2">Nettó</th>
							<th colSpan="2">Bruttó</th>
						</tr>
						<tr key="head" className="table-head-row grill-head-second-row">
							<th>Dátum</th>
							<th>Átadás nettó</th>
							<th>DB</th>
							<th>NetPincér</th>
							<th>Ft</th>
							<th>NetPincér</th>
							<th>Ft</th>
							<th>NetPincér</th>
						</tr>
					</thead>

					{
						<tbody>
							{listOfDays.map((days, idx) => (
								<tr key={idx}>
									<td>{days.replaceAll("-", ".")}</td>
									<td>
										{filteredData.map((data) =>
											data.datum === days
												? data.atadas_netto
													? data.atadas_netto.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{filteredData.map((data) =>
											data.datum === days
												? data.mennyiseg
													? data.mennyiseg.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{filteredData.map((data) =>
											data.datum === days
												? data.mennyiseg_np
													? data.mennyiseg_np.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{filteredData.map((data) =>
											data.datum === days
												? data.ert_netto
													? data.ert_netto.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{filteredData.map((data) =>
											data.datum === days
												? data.ert_netto_np
													? data.ert_netto_np.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{filteredData.map((data) =>
											data.datum === days
												? data.ert_brutto
													? data.ert_brutto.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{filteredData.map((data) =>
											data.datum === days
												? data.ert_brutto_np
													? data.ert_brutto_np.toLocaleString()
													: null
												: null
										)}
									</td>
								</tr>
							))}
						</tbody>
					}
					<tfoot>
						<tr key="head-sum" className="table-head-row grill-sum-row">
							<th>Összesen</th>
							<th>{atadasSum.toLocaleString()}</th>
							<th>{mennyisegSum.toLocaleString()}</th>
							<th>{mennyisegNpSum.toLocaleString()}</th>
							<th>{ertNettoSum.toLocaleString()}</th>
							<th>{ertNettoNpSum.toLocaleString()}</th>
							<th>{ertBruttoSum.toLocaleString()}</th>
							<th>{ertBruttoNpSum.toLocaleString()}</th>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	)
}

