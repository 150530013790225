import React from "react"
import "./table.css"

export default function TableForgAll(props) {

	const sortedBolt = props.sumBoltok.sort((a, b) => {
		return b.fogyar - a.fogyar
	})
	
	return (
		<div className="block-table">
			<div className="block-table-title">
				<table className="styled-table">
					<thead>
						<tr className="table-title-row">
							<th colSpan="5">
								Forgalom megoszlása {props.startDate} - {props.endDate} között
							</th>
						</tr>
						<tr key="head" className="table-head-row">
							<th>Bolt neve</th>
							<th>fogyasztói ár</th>
							<th>Árrés tömeg</th>
							<th>Vevő szám</th>
							<th>Árres %</th>
						</tr>
					</thead>
					<tbody>
						{sortedBolt.map((bolt, idx) => (
							<tr key={bolt.id}>
								<td>{bolt.name}</td>
								<td>{Intl.NumberFormat("hu").format(bolt.fogyar)}</td>
								<td>{Intl.NumberFormat("hu").format(bolt.arres)}</td>
								<td>{Intl.NumberFormat("hu").format(bolt.vevo)}</td>
								<td>
									{Intl.NumberFormat("hu").format(
										bolt.arresszazalek.toFixed(2)
									)}{" "}
									%
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

