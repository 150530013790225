import { Routes, Route } from "react-router-dom"
import PrivateRoute from "./PrivateRoute/private"

//import Nav from './Nav';
import Main from "./pages/Main"
import CikkCsoport from "./pages/CikkCsoport"
import Grill from "./pages/Grill"
import Logisztika from "./pages/Logisztika"
import Trafik from "./pages/Trafik"
import Login from "./pages/Login"
import Register from "./pages/Register"
import ChangePass from "./pages/ChangePass"
import Sidebar from "./components/Sidebar"
import NotFound from "./pages/NotFound"
import ServerError from "./pages/ServerError"
import WithNav from "./utils/Withnav"
import WithoutNav from "./utils/Withoutnav"

function App() {
	
	return (
		<>
			<Routes>
				<Route element={<WithNav />}>
					<Route
						exact
						path="/register"
						element={
							<PrivateRoute>
								<Sidebar />
								<Register />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path="/changepass"
						element={
							<PrivateRoute>
								<Sidebar />
								<ChangePass />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path="/cikkcsoport"
						element={
							<PrivateRoute>
								<Sidebar />
								<CikkCsoport />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path="/grill"
						element={
							<PrivateRoute>
								<Sidebar />
								<Grill />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path="/logisztika"
						element={
							<PrivateRoute>
								<Sidebar />
								<Logisztika />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path="/trafik"
						element={
							<PrivateRoute>
								<Sidebar />
								<Trafik />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path="/"
						element={
							<PrivateRoute>
								<Sidebar />
								<Main />
							</PrivateRoute>
						}
					/>
				</Route>
				<Route element={<WithoutNav />}>
					<Route exact path="/login" element={<Login />} />
				</Route>
				<Route element={<WithoutNav />}>
					<Route exact path="/error" element={<ServerError />} />
				</Route>
				<Route element={<WithoutNav />}>
					<Route exact path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</>
	)}

export default App
