import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext"

import "./Sidebar.css"

export default function Sidebar(props) {
	
	const { sideIsOpen, sideBarOpen } = useContext(AuthContext)

	return (
		<>
			{/* id toggle for sidebar full in mobile view */}
			<div
				id={sideIsOpen ? "mysidebar" : ""}
				className="sidebar"
				style={{width: sideIsOpen ? '250px':'0'}}
			>
				<div className="side-wrapper">
					<ul>
						<hr />
						<li>
							<Link to="/" onClick={sideBarOpen}>
								CBA forgalom
							</Link>
						</li>
						<li>
							<Link to="/logisztika" onClick={sideBarOpen}>
								Logisztika
							</Link>
						</li>
						<li>
							<Link to="/cikkcsoport" onClick={sideBarOpen}>
								Cikk csoport
							</Link>
						</li>
						<li>
							<Link to="/grill" onClick={sideBarOpen}>
								Grill
							</Link>
						</li>
						<hr />
						<li>
							<Link to="/trafik" onClick={sideBarOpen}>
								Trafik forgalom
							</Link>
						</li>
						<hr />
					</ul>
				</div>
			</div>
		</>
	)
}
 