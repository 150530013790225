import React from 'react'
import './table.css'

export default function TableForg({ filteredData, bolt }) {

	return (
		<div className="block-table">
			<div className="block-table-title">
				<table className="styled-table">
					<thead>
						<tr className="table-title-row">
							<th colSpan="5">{bolt}</th>
						</tr>
						<tr key="head" className="table-head-row">
							<th>Dátum</th>
							<th>fogyasztói ár</th>
							<th>Árrés tömeg</th>
							<th>Vevő szám</th>
							<th>Árres %</th>
						</tr>
					</thead>
					<tbody>
						{filteredData.map((bolt, idx) => (
							<tr key={idx}>
								<td>{bolt.datum}</td>
								<td>{Intl.NumberFormat("hu").format(bolt.fogyar)}</td>
								<td>{Intl.NumberFormat("hu").format(bolt.arres)}</td>
								<td>{Intl.NumberFormat("hu").format(bolt.vevoszam)}</td>
								<td>{Intl.NumberFormat("hu").format(bolt.arresSzazalek)} %</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

