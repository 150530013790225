import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom"

import "./login.css"
import PwdInfo from '../utils/PwdInfo';
import useAxios from "../api/useAxios"

export default function ChangePass(props) {
    const emailRef = useRef()
    const passwordRef = useRef()
	const passwordConfirmRef = useRef()
    const [error, setError] = useState('')
	const [pwdLength, setPwdLength] = useState(false)
	const [pwdUc, setPwdUc] = useState(false)
	const [pwdLc, setPwdLc] = useState(false)
	const [pwdDigit, setPwdDigit] = useState(false)
	const [pwdSpecial, setPwdSpecial] = useState(false)
	const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
	const api = useAxios()
	const minPwdLength = 8

	function onPassChange(e) {
		setPwdLength(passwordRef.current.value.length >= minPwdLength ? true : false)
		setPwdUc(/[A-Z]+/.test(passwordRef.current.value))
		setPwdLc(/[a-z]+/.test(passwordRef.current.value))
		setPwdDigit(/[0-9]+/.test(passwordRef.current.value))
		setPwdSpecial(/[^A-Za-z0-9]+/.test(passwordRef.current.value))
	}

	async function handleSubmit(e) {
		e.preventDefault()

		// if pwd not match
		// not long enough
		// Uppercase
		// Lowercase
		// Numeric
		// Special 
		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Jelszavak nem egyeznek")
		} else if (!pwdLength) {
			return setError("túl rövid jelszó")
		}else if (!pwdUc) {
			return setError("Nagy betűt nem tartalmaz")
		} else if (!pwdLc) {
			return setError("Nagy betűt nem tartalmaz")
		} else if (!pwdDigit) {
			return setError("Számot nem tartalmaz")
		} else if (!pwdSpecial) {
			return setError("Speciális karaktert nem tartalmaz")
		}

		let payload = {
			email: emailRef.current.value,
			password: passwordRef.current.value,
		}
		
		const getRegister = async () => {
			const response = await api.post("/changepass", payload)

			if (response.status === 201) {
				setLoading(false)
				navigate("/")
			} else if (response.status === 403) {
				setError("Felhasználó létezik")
			} else if (response.status === 500) {
				setError("szerver hiba " + response.status)
			} else if (!response) {
				setError("szerver hiba")
			}
			setLoading(false)
		}
		getRegister()
		setLoading(false)
	}

    return (
			<div className="login-wrapper">
				<form className="login-form" onSubmit={handleSubmit}>
					<h4 id="login-title">Jelszó változtatás</h4>
					{error && <h5 className="errorTitle">{error}</h5>}
					<label htmlFor="email">Email</label>
					<input type="email" id="email" ref={emailRef} required />

					<label htmlFor="password">Jelszó</label>
					<input
						type="password"
						id="password"
						ref={passwordRef}
						onChange={onPassChange}
						required
					/>
					<label htmlFor="password-confirm">Jelszó ismét</label>
					<input
						type="password"
						id="password-confirm"
						ref={passwordConfirmRef}
						required
					/>

					<button disabled={loading} className="btn" type="submit">
						Jelszó változtatás
					</button>
					<PwdInfo
						pwdLength={pwdLength}
						pwdDigit={pwdDigit}
						pwdUc={pwdUc}
						pwdLc={pwdLc}
						pwdSpecial={pwdSpecial}
						minPwdLength={minPwdLength}
					/>
				</form>
			</div>
		)
}