const boltok = [
	{
		id: "001",
		name: "Centenárium",
	},
	{
		id: "002",
		name: "Szabad Május",
	},
	{
		id: "003",
		name: "Munkásotthon",
	},
	{
		id: "004",
		name: "Legénybíró",
	},
	{
		id: "005",
		name: "Anker",
	},
	{
		id: "006",
		name: "József Attila",
	},
	{
		id: "007",
		name: "Jókai",
	},
	{
		id: "008",
		name: "Kecskemét",
	},
	{
		id: "009",
		name: "Kada",
	},
]
 export default boltok