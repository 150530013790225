import { useContext } from "react"
import {AuthContext} from "../contexts/AuthContext"
import axios from "axios"
import { isExpired } from "react-jwt"

let baseURL =
	process.env.NODE_ENV === "production"
		? process.env.REACT_APP_PROD_API_URL
		: process.env.REACT_APP_DEV_API_URL

const tokenURL =
	process.env.NODE_ENV === "production"
		? process.env.REACT_APP_PROD_AUTH_TOKEN_URL
		: process.env.REACT_APP_DEV_AUTH_TOKEN_URL

const useAxios = () => {
	const { currentUser, setCurrentUser } = useContext(AuthContext)

	const axiosInstance = axios.create({
		baseURL,
		headers: { Authorization: `Bearer ${currentUser?.accessToken}` },
	})

	axiosInstance.interceptors.request.use(	async (req) => {
			if (
				req.url === "/login" ||
				req.url === "/token" ||
				req.url === "/register" ||
				req.url === "/changepass"
			) {
				req.baseURL =
					process.env.NODE_ENV === "production"
						? process.env.REACT_APP_PROD_AUTH_URL
						: process.env.REACT_APP_DEV_AUTH_URL
				return req
			}
		
			const accessExpired = isExpired(currentUser.accessToken)

			if (!accessExpired) return req

			const rToken = localStorage.getItem("refreshtoken")
			const refreshExpired = isExpired(rToken)

			if (!refreshExpired) return req

			const response = await axios.post(tokenURL, {token: rToken,	})
			setCurrentUser({ ...currentUser, accessToken: response.data.accessToken })
			req.headers.Authorization = `Bearer ${response.data.accessToken}`

			return req
		},
		(error) => {
			return Promise.reject(error)
		}
	)

	axiosInstance.interceptors.response.use( async(response) => {
			// do somthing if its needed
			// return response
			return response
		},
		(error) => {		
			return error.response
			//return error.response ? error.response : Promise.reject(new Error(error))
		}
	)

	return axiosInstance
	
}

export default useAxios