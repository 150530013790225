import React, { useRef, useState, useEffect } from "react"
import "./Charts.css"
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	LabelList,
	Tooltip,
	ResponsiveContainer,
} from "recharts"


export default function BarCikk(props) {

	const data = []
	props.filteredData.map(n => data.push(n))

	const [windowSize, setWindowSize] = useState(window.innerWidth)

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize(window.innerWidth)
		}

		window.addEventListener("resize", handleWindowResize)

		return () => {
			window.removeEventListener("resize", handleWindowResize)
		}
	}, [])

	let labelFontSize = useRef(16)
	
	useEffect(() => {
		if (windowSize < 600) {
			labelFontSize.current = 12			
		} else if (windowSize < 500) {
			labelFontSize.current = 7
		} else if (windowSize < 400) {
			labelFontSize.current = 6
		} else {
			labelFontSize.current = 16
		}
	}, [windowSize])

	function formatCurr(value) {
		return Intl.NumberFormat("hu").format(value)
	}

	const renderCustomizedLabel = (props) => {
		const { x, y, height, value } = props

		return (
			<g>
				<text
					x={x}
					y={y + height /1.5}
					fill="black"
					fontSize={labelFontSize.current}
				>
					{value}
				</text>
			</g>
		)
	}

	const renderCustomizedLabelMennyiseg = (props) => {
		const { x, y, width, height, value } = props

		return (
			<g>
				<text
					x={x + width / 2}
					y={y + height / 2}
					fill="white"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={labelFontSize.current}
				>
					{Intl.NumberFormat("hu").format(value)}
				</text>
			</g>
		)
	}

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
				return (
					<div className="custom-tooltip">
						<table>
							<tbody>
								<tr>
									<td>Termék:</td>
									<td className="tooltip-data">{label}</td>
								</tr>
								<tr>
									<td>Mennyiség:</td>
									<td className="tooltip-data">
										{payload[0].payload.mennyiseg}
									</td>
								</tr>
								<tr>
									<td>Fogy. ár:</td>
									<td className="tooltip-data">
										{formatCurr(payload[0].payload.fogyar)} Ft.
									</td>
								</tr>
								<tr>
									<td>árrés:</td>
									<td className="tooltip-data">
										{formatCurr(payload[0].payload.arres)} Ft.
									</td>
								</tr>
								<tr>
									<td>EAN:</td>
									<td className="tooltip-data">
										{payload[0].payload.ean}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				)
			} 

		return null
	}

	return (
		<div className="block">
			<div className="block-title bar">
				<h5 className="title-date">
					{props.bolt} Top {props.limit} termék{" "}
					<span>
						{props.startDate} - {props.endDate}
					</span>{" "}
					között
				</h5>
				<h5 className="title-cikk">
					Nomenklatúra: <span>{props.cikk.nev}</span>
				</h5>
			</div>
			<ResponsiveContainer width="99%" aspect={2.5}>
				<BarChart
					data={data}
					margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
					width={500}
					layout="vertical"
				>
					<YAxis dataKey="cikk_nev" type="category" hide={true} />
					<XAxis type="number" domain={[0, "dataMax + 2"]} hide />
					<Tooltip
						content={<CustomTooltip />}
						wrapperStyle={{ outline: "none" }}
					/>

					<Bar
						dataKey="mennyisegNr"
						fill="#416CBA"
						isAnimationActive={false}
						barSize={40}
					>
						<LabelList
							dataKey="mennyisegNr"
							content={renderCustomizedLabelMennyiseg}
						/>
					</Bar>
					<Bar
						dataKey="mennyisegNr"
						isAnimationActive={false}
						fillOpacity="0"
						barSize={40}
					>
						
						<LabelList
							dataKey="cikk_nev"
							content={renderCustomizedLabel}
						/>
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</div>
	)
}
