import React, { useContext }  from 'react';
import { Link } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext"

export default function Nav() {
    const { currentUser, logout, sideBarOpen, sideIsOpen } =
			useContext(AuthContext)

    const handleLogout = (e) => {
			e.preventDefault()
			logout()
    }

	const barOneStyle = {
		transform: "translate(0, 7px) rotate(-45deg)"
	}

	const barTwoStyle = {opacity: 0}

	const barThreeStyle = {
		transform: "translate(0, -11px) rotate(45deg)"
	}

    return (
			<header>
				<nav className="nav">
					<div className="container-hamburger" onClick={sideBarOpen}>
						<div className="bar1" style={sideIsOpen ? barOneStyle : null}></div>
						<div className="bar2" style={sideIsOpen ? barTwoStyle : null}></div>
						<div
							className="bar3"
							style={sideIsOpen ? barThreeStyle : null}
						></div>
					</div>
					<Link to="/" className="site-title">
						Lázár BI server
					</Link>
					<ul>
						{!!currentUser.isAdmin && (
							<>
								<Link to="/changepass">
									<i className="fa-solid fa-circle-info"></i>
								</Link>
								<Link to="/register">
									<i className="fa-solid fa-user-plus"></i>
								</Link>
							</>
						)}
						<Link to="/logout" onClick={handleLogout}>
							<img className="logout-img" src="logout-xxl.png" alt="log out" />
						</Link>
					</ul>
				</nav>
			</header>
		)
}; 
    
   
