import React from 'react'
import "./table.css"

export default function TableLogisztika({ data, bolt, listOfDays }) {

	data.forEach(
		(row) => (
			(row.brutto_fogy =
				row.brutto_fogy !== 0
					? parseInt(row.brutto_fogy)
					: 0),
			(row.netto_fogy =
				row.netto_fogy !== 0
					? parseInt(row.netto_fogy)
					: 0),
			(row.netto_nyilv =
				row.netto_nyilv !== 0
					? parseInt(row.netto_nyilv)
					: 0),
			(row.vevo =
				row.vevo !== 0
					? parseInt(row.vevo)
					: 0),
			(row.arrestomeg =
				row.netto_fogy !== 0
					? parseInt(row.netto_fogy - row.netto_nyilv)
					: 0),
			(row.arresszazalek =
				row.netto_fogy !== 0
					? row.arrestomeg*100/row.netto_fogy
					: 0)
		)
	)

	let bruttoFogyInit = 0
	let nettoFogyInit = 0
	let nettoFogyNyilvInit = 0
	let arrestomegInit = 0
	let arresszazalekAvgInit = 0
	let vevoInit = 0

	const bruttoSum = data.reduce(function (acc, curr) {
		return acc + curr.brutto_fogy
	}, bruttoFogyInit)

	const nettoFogySum = data.reduce(function (acc, curr) {
		return acc + curr.netto_fogy
	}, nettoFogyInit)

	const nettoNyilvSum = data.reduce(function (acc, curr) {
		return acc + curr.netto_nyilv
	}, nettoFogyNyilvInit)

	const arrestomegSum = data.reduce(function (acc, curr) {
		return acc + curr.arrestomeg
	}, arrestomegInit)

	const filterAvg = data.filter((item) => item.arresszazalek !== 0)

	const arresszazalekAvg = filterAvg.reduce(function (acc, curr, _, {length}) {
		return acc + curr.arresszazalek / length
	}, arresszazalekAvgInit)

	const vevoSum = data.reduce(function (acc, curr) {
		return acc + curr.vevo
	}, vevoInit)

	return (
		<div className="block-table">
			<div className="block-table-title">
				<table className="styled-table logisztika-table">
					<thead>
						<tr className="table-title-row">
							<th colSpan="7" className="table-bolt-row">
								<span className="title-color-table">Logisztika</span> forgalmi
								adataok{" "}
								<span className="title-color-table">
									{listOfDays[0]} - {listOfDays[listOfDays.length - 1]}
								</span>{" "}
								között
							</th>
						</tr>
						<tr key="head-title" className="table-head-row logisztika-head-row">
							<th>Dátum</th>
							<th>Nettó nyilv</th>
							<th>Nettó fogy</th>
							<th>Bruttó fogy</th>
							<th>Árrés tömeg Netto</th>
							<th>Árrés %</th>
							<th>Vevő</th>
						</tr>
					</thead>

					{
						<tbody>
							{listOfDays.map((days, idx) => (
								<tr key={idx}>
									<td>{days.replaceAll("-", ".")}</td>
									<td>
										{data.map((data) =>
											data.datum === days
												? data.netto_nyilv
													? data.netto_nyilv.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{data.map((data) =>
											data.datum === days
												? data.netto_fogy
													? data.netto_fogy.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{data.map((data) =>
											data.datum === days
												? data.brutto_fogy
													? data.brutto_fogy.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{data.map((data) =>
											data.datum === days
												? data.arrestomeg
													? data.arrestomeg.toLocaleString()
													: null
												: null
										)}
									</td>
									<td>
										{data.map((data) =>
											data.datum === days
												? data.arresszazalek
													? data.arresszazalek.toFixed(2)
													: null
												: null
										)}
									</td>
									<td>
										{data.map((data) =>
											data.datum === days
												? data.vevo
													? data.vevo.toLocaleString()
													: null
												: null
										)}
									</td>
								</tr>
							))}
						</tbody>
					}
					<tfoot>
						<tr key="head-sum" className="table-head-row grill-sum-row">
							<th>Összesen</th>
							<th>{bruttoSum.toLocaleString()}</th>
							<th>{nettoNyilvSum.toLocaleString()}</th>
							<th>{nettoFogySum.toLocaleString()}</th>
							<th>{arrestomegSum.toLocaleString()}</th>
							<th>{arresszazalekAvg.toFixed(2)}</th>
							<th>{vevoSum.toLocaleString()}</th>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	)
}

