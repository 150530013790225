import React, { createContext, useState, useCallback } from "react"

export const AuthContext = createContext()

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState({})
	const [sideIsOpen, setSideIsOpen] = useState(false)

	// loading state for useeffect need time to load
	// only render if not loading
	//const [loading, setLoading] = useState(true)

	const sideBarOpen = () => {
		setSideIsOpen((prevState) => !prevState)
	}

	const logout = useCallback(() => {
		localStorage.removeItem("refreshtoken")
		setCurrentUser({})
	}, []);


	const contextValue = {
		currentUser,
		setCurrentUser,
		logout,
		sideBarOpen,
		sideIsOpen
	}

	return (
		<AuthContext.Provider value={contextValue}>
			{children}
			{/* {!loading && children} */}
		</AuthContext.Provider>
	)
}