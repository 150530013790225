import { Link } from "react-router-dom"
import "./NotFound.css"

export default function NotFound(props) {
    

    return (
			
					<div className="notfound-card">
						<h1>
							4<span>0</span>4
						</h1>
						<h2>A keresett oldal nem taláható</h2>
						<Link to="/">
							<button className="btn">Kezdő oldal</button>
						</Link>
					</div>
				
		)
}; 
    
   
