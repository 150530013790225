const trafikBoltok = [
	{
		id: "001",
		name: "Anker",
		bt: "Tobacco Leaves Bt.",
		btId: "000",
		tulaj: "Anikó",
	},
	{
		id: "303",
		name: "Bartók Béla",
		bt: "Roken Tobacco Bt.",
		btId: "300",
		tulaj: "Feri",
	},
	{
		id: "102",
		name: "Budaörsi út",
		bt: "Tobacconist Trade Bt.",
		btId: "100",
		tulaj: "Tomi",
	},
	{
		id: "401",
		name: "Centenárium",
		bt: "Sun Leaves Tobacco Bt.",
		btId: "400",
		tulaj: "Timi",
	},
	{
		id: "101",
		name: "Damjanich",
		bt: "Tobacconist Trade Bt.",
		btId: "100",
		tulaj: "Tomi",
	},
	{
		id: "002",
		name: "Jókai Mór",
		bt: "Tobacco Leaves Bt.",
		btId: "000",
		tulaj: "Anikó",
	},
	{
		id: "402",
		name: "József Attila",
		bt: "Sun Leaves Tobacco Bt.",
		btId: "400",
		tulaj: "Timi",
	},
	{
		id: "103",
		name: "Legénybíró",
		bt: "Tobacco Leaves Bt.",
		btId: "000",
		tulaj: "Anikó",
	},
	{
		id: "302",
		name: "Sugár",
		bt: "Roken Tobacco Bt.",
		btId: "300",
		tulaj: "Feri",
	},
	{
		id: "301",
		name: "Szabad Május",
		bt: "Roken Tobacco Bt.",
		btId: "300",
		tulaj: "Feri",
	},
	{
		id: "003",
		name: "Thököly",
		bt: "Tobacconist Trade Bt.",
		btId: "100",
		tulaj: "Tomi",
	},
]
 export default trafikBoltok