import React, {useRef, useState, useEffect} from 'react'

import { PieChart, Pie, Label, Cell, ResponsiveContainer } from "recharts"

export default function PieFogy(props) {

	const sortedBolt = props.sumBoltok.sort((a, b) => {
		return b.fogyar - a.fogyar
	})

	const [windowSize, setWindowSize] = useState(window.innerWidth)

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize(window.innerWidth)
		}

		window.addEventListener("resize", handleWindowResize)

		return () => {
			window.removeEventListener("resize", handleWindowResize)
		}
	}, [])

	let labelFontSize = useRef(20)

	useEffect(() => {
		if (windowSize > 700) {
			labelFontSize.current = 20
		} else if (windowSize < 700 && windowSize > 600) {
			labelFontSize.current = 16
		} else if (windowSize < 600 && windowSize > 500) {
			labelFontSize.current = 14
		} else if (windowSize < 500 && windowSize > 400) {
			labelFontSize.current = 12
		} else if (windowSize < 400) {
			labelFontSize.current = 10
		}
	}, [windowSize])
	
	const sumForgalom = props.sumBoltok.reduce(function (acc, curr) {
		return acc + curr.fogyar
	}, 0)

	const COLORS = [
		"#38c172",
		"#4dc0b5",
		"#3490dc",
		"#6574cd",
		"#9561e2",
		"#633d27",
		"#f6993f",
		"#f66d9b",
		"#e3342f",
		"#d408d0",
		"#07080a",
	]

	return (
		<div className="block">
			<div className="block-title pie">
				<h3>
					Forgalom megoszlása
					<span className='title-color'>{" "}
						{props.startDate} - {props.endDate}
					</span>{" "}
					között
				</h3>
			</div>
			<ResponsiveContainer width="99%" aspect={1.8}>
				<PieChart key={Math.random()} width={400} height={400}>
					<Pie
						data={sortedBolt}
						dataKey="fogyar"
						cx="50%"
						cy="50%"
						outerRadius="65%"
						innerRadius="50%"
						fill="#82ca9d"
						label={({
							cx,
							cy,
							midAngle,
							innerRadius,
							outerRadius,
							value,
							index,
						}) => {
							const RADIAN = Math.PI / 180
							// eslint-disable-next-line
							const radius = 30 + innerRadius + (outerRadius - innerRadius)
							// eslint-disable-next-line
							const x = cx + radius * Math.cos(-midAngle * RADIAN)
							// eslint-disable-next-line
							const y = cy + radius * Math.sin(-midAngle * RADIAN)

							return (
								<text
									x={x}
									y={y}
									fill={COLORS[index]}
									textAnchor={x > cx ? "start" : "end"}
									dominantBaseline="central"
									fontSize={labelFontSize.current}
									fontWeight={"bold"}
								>
									{windowSize > 500
										? sortedBolt[index].name
										: sortedBolt[index].name.slice(0, 4)}{" "}
									(
									{Intl.NumberFormat("hu").format(
										((value / sumForgalom) * 100).toFixed(2)
									) + " %"}
									)
								</text>
							)
						}}
					>
						{sortedBolt.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={COLORS[index % COLORS.length]}
							/>
						))}
						<Label
							value={Intl.NumberFormat("hu").format(sumForgalom)}
							position="center"
							fontWeight={700}
							dy={-10}
							fill="blue"
							fontSize={labelFontSize.current + 4}
						/>
						<Label
							value={"100%"}
							fill="blue"
							position="center"
							dy={10}
							fontWeight={700}
							fontSize={labelFontSize.current + 4}
						/>
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		</div>
	)
}
