import React, { useState, useEffect } from "react"
import useAxios from "../api/useAxios"
import boltok from "../data/Data"
import Tree from "../components/Tree/Tree"
import Filter from "../components/Filter"
import BarCikk from "../components/Charts/BarCikk"
import {DateUtil} from "../utils/DateUtil"
import ScrollButton from "../components/ScrollButton"

import "./CikkCsoport.css"

import { Slide, ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


export default function CikkCsoport(props) {
	const months = 0
	const days = 7
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [treeLoading, setTreeLoading] = useState(true)
	const [selectCikkItem, setSelectCikkItem] = useState({})
	const [limit, setLimit] = useState(5)
	const [topData, setTopData] = useState([])
	const [startDate, setStartDate] = useState(DateUtil(months, days).start)
	const [endDate, setEndDate] = useState(DateUtil(months, days).end)
	const [counter, setCounter] = useState(0)
	const api = useAxios()

	

	function scrollDown () {
		window.scrollBy(0, 300,{ behavior: "smooth" })
	}

	//run at first render
	useEffect(() => {
		getNomenklatura()
		setLoading(true)
		// eslint-disable-next-line
	}, [])

	//diable button if no cikk selection
	useEffect(() => {
		Object.keys(selectCikkItem).length === 0 
		? setLoading(true) 
		: setLoading(false)
		
	}, [selectCikkItem])

	//set loading while fetch data
	useEffect(() => {
		if (topData.length > 9) {
			setLoading(false)
			setCounter((prev) => prev + 1)
			if (counter === 7) {
				scrollDown()
			}
		}
		// eslint-disable-next-line
	}, [topData])

	//set loading while fetch tree  data
	useEffect(() => {
		if (data.length > 1) {
			setTreeLoading(false)
		}
	}, [data])

	const getNomenklatura = async () => {
		const response = await api.get(`nomenklatura`)

		if (response.status === 200) {
			setData(response.data)
		}
	}

	const getCikkCsoport = async (bolt, filteredDate) => {
		const response = await api.get(
			`cikkcs/${filteredDate}/${bolt}/${selectCikkItem.kod}/${limit}`
		)

		if (response.status === 200) {
			response.data.map((data) =>
				setTopData((prevState) => [...prevState, data])
			)
		}
	}

	function handleFilter(start, end) {
		setLoading(true)
		setTopData([])
		setCounter(0)
		setStartDate(start)
		setEndDate(end)
		const filterDate = start + "/" + end
		boltok.map((bolt) => getCikkCsoport(bolt.id, filterDate))
	}

	function filterData(bolt) {
		const parseArr = topData
			.filter((n) => n.egyseg === bolt)
			.map((dta) => {
				return { ...dta, mennyisegNr: Number(dta.mennyiseg) }
			})

		return parseArr
	}

	function handleDecrease() {
		if (limit >= 4) {
			setLimit((limit) => limit - 1)
		}
	}

	function handleIncrease() {
		if (limit <= 9) {
			setLimit((limit) => limit + 1)
		}
	}

	return (
		<div className="main">
			<ScrollButton />
			<div className="main-wrapper">
				<ToastContainer
					position="top-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnHover
					theme="dark"
					transition={Slide}
				/>

				<div className="block-cikk">
					<div className="cikk-title">
						<h4>Cikkelemes forgalom Nomenklatúra alapján</h4>
					</div>
					<hr className="cikk-hr"></hr>
					{!treeLoading && (
						<Tree
							data={data}
							setSelectCikkItem={setSelectCikkItem}
							selectCikkItem={selectCikkItem}
						/>
					)}
				</div>
				<Filter
					toast={toast}
					selectCikkItem={selectCikkItem}
					getData={handleFilter}
					isLimit={true}
					limit={limit}
					loading={loading}
					handleDecrease={handleDecrease}
					handleIncrease={handleIncrease}
					months={months}
					days={days}
				/>
				{topData.length > 9 && (
					<>
						{boltok.map((bolt, index) => (
							<BarCikk
								key={index}
								bolt={bolt.name}
								limit={limit}
								filteredData={filterData(bolt.id)}
								cikk={selectCikkItem}
								startDate={startDate}
								endDate={endDate}
							/>
						))}
					</>
				)}
			</div>
		</div>
	)
}