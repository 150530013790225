import Branch from './Branch';

const Tree = ({data, selectCikkItem, setSelectCikkItem}) => {

	
	return (
		<div className="tree">
			{data.map((item) => (
				<Branch
					key={item.id}
					item={item}
					level={0}
					selectCikkItem={selectCikkItem}
					setSelectCikkItem={setSelectCikkItem}
				/>
			))}
		</div>
	)
}

export default Tree;