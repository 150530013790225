import React, { useEffect, useRef, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { decodeToken } from "react-jwt"

import {AuthContext} from "../contexts/AuthContext"
import "./login.css"
import useAxios from '../api/useAxios'

function Login(props) {
	const { setCurrentUser } = useContext(AuthContext)

    const emailRef = useRef()
    const passwordRef = useRef()
	const a_passwordRef = useRef()
    const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

	const api = useAxios()

	//set focus to email at start
	useEffect(() => {
		emailRef.current.focus()	
	}, [])

	useEffect(() => {
		// Load reCAPTCHA script and initialize
		if (!document.getElementById("captcha")) {
			const script = document.createElement("script")
			script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`
			document.body.appendChild(script)
			script.setAttribute("id", "captcha")

			script.onload = () => {
				// Initialize reCAPTCHA with your site key
				window.grecaptcha.ready(() => {
					// You can use this callback to execute code after reCAPTCHA script is loaded and ready
					setLoading(false)
				})
			}
		}
	}, [])

	//set error to empty when email or password changing
	useEffect(() => {
		setError('')
	}, [emailRef, passwordRef])

	const handleRecaptcha = async () => {
		if (typeof window !== "undefined" && window.grecaptcha) {
			const captcha = await window.grecaptcha.execute(
				process.env.REACT_APP_SITE_KEY,
				{
					action: "submit",
				}
			)
			return captcha
		}
		return ""
	}
	
	const handleSubmit = async (e) => {
		e.preventDefault()
		setError("")
		setLoading(true)
		
		try {

			const captcha = await handleRecaptcha() // Call handleRecaptcha to get the token
			
			if (captcha) {
				//set json payload
				let payload = {
					email: emailRef.current.value,
					password: passwordRef.current.value,
					a_password: a_passwordRef.current.value,
					captcha: captcha
				}

				const getData = async () => {
					const response = await api.post("/login", payload)

					if (response === undefined) {
						console.log('no response')
						setError("szerver hiba, próbálja később")
					} else if (response.status === 201) {
						setCurrentUser({
							email: decodeToken(response.data.accessToken).email,
							accessToken: response.data.accessToken,
							isAdmin: decodeToken(response.data.accessToken).isAdmin,
						})
						localStorage.setItem("refreshtoken", response.data.refreshToken)
						setLoading(false)
						navigate("/")
					} else if (response.status === 404) {
						console.log(response)
						setError("hibás felhasználó/jelszó")
					} else if (response.status === 500) {
						console.log(response)
						setError("szerver hiba, póbálja később")
					}
					setLoading(false)
				}
				getData()
				setLoading(false)
			} else {
				setError("Captcha hiba")
			}
		} catch (err){
			console.log(err)
			setError("Hiba, próbálja később")
		}
		setLoading(false)
	}

    return (
			<div className="login-wrapper">
				<form className="login-form" onSubmit={handleSubmit}>
					<img className="logo" src="bilogo.png" alt="logo" />
					<h4 id="login-title">LOGIN</h4>
					{error && <h5 className="errorTitle">{error}</h5>}
					{/* This would be a bot field with type="hidden" */}
					<input
						name="a_password"
						placeholder="do not fill this"
						type="hidden"
						tabIndex="-1"
						autoComplete="false"
						ref={a_passwordRef}
					/>
					<label htmlFor="email">Email</label>
					<input
						type="email"
						name="email"
						id="email"
						ref={emailRef}
						autoComplete="email"
						autofill="enabled"
						required
					/>

					<label htmlFor="password">Jelszó</label>
					<input
						type="password"
						name="password"
						id="password"
						ref={passwordRef}
						autoComplete="password"
						autofill="enabled"
						required
					/>
					<button disabled={loading} className="btn" type="submit">
						Belépés
					</button>
				</form>
			</div>
		)
}

export default Login