function subtractMonths(date, months) {
	date.setMonth(date.getMonth() - months)
	return date
}

//return date before today by args months or days
function DateUtil(month, days) {
	const today = new Date()
	const yesterDay = new Date(new Date().setDate(today.getDate() - 1))
		.toISOString()
		.substring(0, 10)

	let startDate = new Date()

	if (month > 0) {
		startDate = subtractMonths(new Date(), 1).toISOString().substring(0, 10)
	} else {
		startDate = new Date(new Date().setDate(today.getDate() - days))
			.toISOString()
			.substring(0, 10)
	}

	const fullDate = (startDate + "/" + yesterDay).replace(/-/g, ".")

	return {
		start: startDate,
		end: yesterDay,
		fullDate: fullDate,
	}
}


function getFirstLastDay(year, month) {
	const formatMonth = ("0" + (month + 1)).slice(-2)
	let firstDate = new Date(year, formatMonth - 1, 1)
	/* firstDate = new Date(firstDate.getTime() - firstDate.getTimezoneOffset() * 60000)
			.toISOString()
			.split("T")[0] */
	let lastDate = new Date(year, formatMonth, 0)
	/* lastDate = new Date(lastDate.getTime() - lastDate.getTimezoneOffset() * 60000)
			.toISOString()
			.split("T")[0] */
	return [firstDate, lastDate]
}


export { DateUtil, getFirstLastDay }