import React, { useState } from "react"

const ScrollButton = () => {
	const [visible, setVisible] = useState(false)

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop
		if (scrolled > 300) {
			setVisible(true)
		} else if (scrolled <= 300) {
			setVisible(false)
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
			/* you can also use 'auto' behaviour
         in place of 'smooth' */
		})
	}

	window.addEventListener("scroll", toggleVisible)

	return (	
        <i
            className="fa-regular fa-circle-up fa-2xl top-button"
            onClick={scrollToTop}
            style={{ display: visible ? "inline" : "none" }}
        ></i>
	)
}

export default ScrollButton
