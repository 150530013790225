import React, { useRef } from "react"
import "./Filter.css"
import {DateUtil} from "../utils/DateUtil"


export default function Filter(props) {

	const startInput = useRef(null)
	const endInput = useRef(null)
	const monthInput = useRef(null)

	function handleSearchClick(e) {
		e.preventDefault()
		if (props.monthPicker){
			props.getData(monthInput.current.value.replace(/-/g, "."))
		} else {
			if (endInput.current.value <= startInput.current.value) {
				/* startInput.current.value = startDate */
				endInput.current.value = props.yesterDay
				props.toast.error("Vége dátum hiba")
			} else if (startInput.current.value >= props.yesterDay) {
				/* startInput.current.value = startDate */
				props.toast.error("Kezdő dátum hiba")
			} else {
				const start = startInput.current.value.replace(/-/g, ".")
				const end = endInput.current.value.replace(/-/g, ".")
				props.getData(start, end)
			}
		}
	}
	
	//add 0 to sting for month picker, if default two digit then slice the 0
	// format for input month
	const formatMonth = ('0' + (props.selectedMonth + 1)).slice(-2)
	const selectedDate = props.selectedYear + '-' + formatMonth

	return (
		<div className="filter-wrapper">
			<div className="filter-title">
				<h4>Szűrő</h4>
			</div>

			<div className="filter-date-wrap">
				{!props.monthPicker ? (
					<>
						<div className="inpt-grp">
							<span className="inpt-grp-icon">Kezdet:</span>
							<input
								type="date"
								id="startInput"
								name="startInput"
								min={"2022-01-01"}
								ref={startInput}
								defaultValue={DateUtil(props.months, props.days).start}
							></input>
						</div>

						<div className="inpt-grp">
							<span className="inpt-grp-icon">Vége:</span>
							<input
								type="date"
								id="endInput"
								name="endInput"
								max={props.yesterDay}
								ref={endInput}
								defaultValue={DateUtil(props.months, props.days).end}
							></input>
						</div>
					</>
				) : (
					<div className="inpt-grp">
						<span className="inpt-grp-icon">Hónap:</span>
						<input
							type="month"
							id="monthInput"
							name="monthInput"
							min={"2022-05-01"}
							ref={monthInput}
							defaultValue={selectedDate}
						></input>
					</div>
				)}

				{props.isLimit && (
					<div className="inpt-grp">
						<span className="inpt-grp-icon input-top">top: </span>
						<div id="topCount" className="counter">
							<button className="quantity-btn" onClick={props.handleDecrease}>
								<i className="fa-solid fa-minus"></i>
							</button>
							<span className="counter-limit">{props.limit}</span>
							<button className="quantity-btn" onClick={props.handleIncrease}>
								<i className="fa-solid fa-plus"></i>
							</button>
						</div>
					</div>
				)}

				<button
					className="filter-btn"
					onClick={handleSearchClick}
					disabled={props.loading}
				>
					Lekérdez <i className="fa-sharp fa-solid fa-magnifying-glass"></i>
				</button>
			</div>
			{props.onOptionChange && (
				<>
					<hr className="flip-hr"></hr>
					<div className="chart-table">
						<input
							type="radio"
							value={true}
							name="chartOrTable"
							label="Diagram"
							id="chart"
							onChange={props.onOptionChange}
							defaultChecked={true}
						/>
						<label htmlFor="chart">Diagram</label>

						<input
							type="radio"
							value={false}
							label="Tábla"
							name="chartOrTable"
							id="table"
							onChange={props.onOptionChange}
						/>
						<label htmlFor="table">Tábla</label>
					</div>
				</>
			)}
			{props.selectCikkItem.nev && (
				<>
					<hr className="flip-hr"></hr>
					<div className="filter-selected">
						<span>Kiválasztott elem</span>
						<div className="filter-selected-item">
							{props.selectCikkItem.nev}
						</div>
					</div>
				</>
			)}
		</div>
	)
}
