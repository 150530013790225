import React, {useContext} from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from "../contexts/AuthContext"


export default function PrivateRoute({ children }) {
    const { currentUser } = useContext(AuthContext)

	return currentUser.accessToken ? children : <Navigate to="/login" />
}
